const demandsList = [
	3.0,
	5.0,
	6.5,
	8.0,
	9.4,
	10.7,
	11.8,
	12.8,
	13.7,
	14.6,
	15.4,
	16.0,
	16.5,
	17.0,
	17.5,
    18.0,
    18.4,
    18.8,
    19.2,
    19.6,
    21.5,
    23.3,
    24.9,
    26.3,
    27.7,
    29.1,
    32.0,
    35.0,
    38.0,
    41.0,
    43.5,
    48.0,
    52.5,
    57.0,
    61.0,
    65.0,
    70.0,
    75.0,
    80.0,
    85.0,
    105.0,
    124.0,
    170.0,
    208.0,
    239.0,
    269.0,
    297.0,
    325.0,
    380.0,
    433.0,
    525.0,
    593.0
];

export {demandsList};