const sizesList = [
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'3/4"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1"',
	'1 1/4"',
	'1 1/4"',
	'1 1/4"',
	'1 1/4"',
	'1 1/4"',
	'1 1/4"',
	'1 1/4"',
	'1 1/2"',
	'1 1/2"',
	'1 1/2"',
	'1 1/2"',
	'1 1/2"',
	'1 1/2"',
	'2"',
	'2"',
	'2"',
	'2"',
	'2"',
	'2"',
	'2 1/2"',
	'2 1/2"',
	'2 1/2"',
	'3"',
	'3"',
	'4"',
	'4"',
	'4"',
	'4"',
	'6"',
	'6"',
	'6"',
	'6"',
	'6"',
];

export default sizesList;