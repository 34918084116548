const gpmsList = [
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	60,
	70,
	80,
	90,
	100,
	120,
	140,
	160,
	180,
	200,
	225,
	250,
	275,
	300,
	400,
	500,
	750,
	1000,
	1250,
	1500,
	1750,
	2000,
	2500,
	3000,
	4000,
	5000
];

export default gpmsList;